import React from 'react';
import loadable from '@loadable/component';
import {GreenHouseData} from './useGreenHouse';

export type CareersRenderProps = GreenHouseData;

export interface CareersDataLoaderProps {
  children: (props: CareersRenderProps) => JSX.Element;
}

const GreenHouseLoader = loadable.lib(() =>
  import(
    /* webpackChunkName: "careers-teams-locations"  */
    '@util/useGreenHouse'
  ),
);

interface CareersDataRendererProps {
  children: CareersDataLoaderProps['children'];
  useGreenHouse: () => GreenHouseData;
}

const CareersDataRenderer = ({
  children,
  useGreenHouse,
}: CareersDataRendererProps): JSX.Element => {
  const careersData = useGreenHouse();
  return children(careersData);
};

export default function CareersDataLoader({
  children,
}: CareersDataLoaderProps): JSX.Element {
  return (
    <GreenHouseLoader>
      {({default: useGreenHouse}) => (
        <CareersDataRenderer useGreenHouse={useGreenHouse}>
          {children}
        </CareersDataRenderer>
      )}
    </GreenHouseLoader>
  );
}
